import {SLUGS} from '@px/const/slugs';
import {appendTemplates} from '@px/helper/template';

export const templates: Template[] = [
  {
    slug: SLUGS.ACCOUNT_OVERVIEW,
    loader: () => import('px-home/src/modules/account/overview'),
  },
  {
    slug: SLUGS.ACCOUNT_VERIFICATION,
    //TODO
    loader: () => import('px-home/src/modules/account/verification'),
  },
  {
    slug: SLUGS.ACCOUNT_PREMIUM_CENTER,
    loader: () => import('px-home/src/modules/account/premium-center'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY,
    loader: () => import('px-home/src/modules/account/security'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY_PASSKEY,
    loader: () => import('px-home/src/modules/account/security/passkeys'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY_DEVICE_MANAGE,
    loader: () => import('px-home/src/modules/account/security/device/device-manage'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY_AUTHORIZATION,
    loader: () => import('px-home/src/modules/account/security/authorization'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY_DEVICE_HISTORY,
    loader: () => import('px-home/src/modules/account/security/device/device-history'),
  },
  {
    slug: SLUGS.ACCOUNT_API_MANAGEMENT,
    loader: () => import('px-home/src/modules/account/api-management'),
  },
  {
    slug: SLUGS.ACCOUNT_TAX_REPORTING,
    loader: () => import('px-home/src/modules/account/tax-reporting'),
  },
  {
    slug: SLUGS.ACCOUNT_SUB_ACCOUNTS,
    loader: () => import('px-home/src/modules/account/sub-accounts'),
  },
  {
    slug: SLUGS.ACCOUNT_INVITE_FRIENDS,
    loader: () => import('px-home/src/modules/referral-friends'),
  },
  {
    slug: SLUGS.ACCOUNT_INVITE_FRIENDS_ENTRY,
    loader: () => import('px-home/src/modules/referral-entry'),
  },
  {
    slug: SLUGS.ACCOUNT_SETTING,
    loader: () => import('px-home/src/modules/account/setting'),
  },
  {
    slug: SLUGS.ACCOUNT_SECURITY_WITHDRAW_LIMIT,
    loader: () => import('px-home/src/modules/account/security/withdraw-limit'),
  },
];

appendTemplates(templates);
